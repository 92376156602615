import { graphql } from 'gatsby'
import { pick } from 'lodash'

import BaseEntry from './base-entry'

const ProductsEntry = ({ data, ...props }) => (
  <BaseEntry
    pagination={{ ...pick(props.pageContext, ['limit', 'totalPages', 'childComponent']), data: data.products.edges }}
    {...props}
  />
)

export const productsQuery = graphql`
  query getProducts($limit: Int!, $childComponent: String!) {
    products: allStoryblokEntry(
      filter: { field_component: { eq: $childComponent } }
      sort: { fields: field_year_string, order: DESC }
      limit: $limit
    ) {
      edges {
        node {
          field_component
          tag_list
          name
          full_slug
          content
          uuid
        }
      }
    }
  }
`

export default ProductsEntry
